import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CreateCouponNotifcationApi, memberListApi } from "service/notifactionService";
import { showAlert } from "components/Common/AlertMessage";
import { Input, Label, Spinner } from "reactstrap";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import { Type } from "react-bootstrap-table2-editor";
import Select from "react-select";

// Added by Parth Roka
const CouponCreateNotification = (props) => {
  const [type, setType] = useState("");
  const [method, setMethod] = useState("");
  const [discounts, setDiscounts] = useState("");
  const [maximumUsage, setMaximumUsage] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const convertedValue = couponCode ? couponCode.toUpperCase() : "";
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [typeError, setTypeError] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [maximumUsageError, setMaximumUsageError] = useState("");
  const [couponCodeError, setCouponCodeError] = useState("");
  const [startDateError, setStartDateErros] = useState("");
  const [endDateError, setEndDateErrors] = useState("");
  const [unlimited, setUnlimited] = useState(false);

  const [loading, setLoading] = useState(false)

  // main function
  const handleSubmit = (e) => {
    if (type) {
      setTypeError("");
    } else {
      setTypeError("Please select username");
    }
    if (!maximumUsage) {
      setMaximumUsageError("Please enter Maximum Credit");
    } else {
      setMaximumUsageError("");
    }
    if (!couponCode) {
      setCouponCodeError("Please enter coupon code");
    } else {
      setCouponCodeError("");
    }

    if (unlimited == true) {
      if (!startDate) {
        setStartDateErros("Please select start date");
      } else {
        setStartDateErros("");
      }
      if (!endDate) {
        setEndDateErrors("Please select end date");
      } else {
        setEndDateErrors("");
      }
    }

    if (unlimited == true) {
      if (couponCode && startDate && endDate && Type && maximumUsage) {
        getNotifcation();
      }
    } else {
      if (couponCode && Type && maximumUsage) {
        getNotifcation();
      }
    }
  };
  const handleStartDate = (e) => {
    let value = e;
    if (value) {
      if (new Date(endDate) >= new Date(value)) {
        setStartDate(value);
      } else {
        setStartDate(null);
      }
    }
  };
  const handleUnlimited = (e) => {
    setUnlimited(e.target.checked);
    let value = e.target.checked;
    if (value == false) {
      setStartDate("");
      setEndDate("");
    }
  };

  const handleEndDate = (e) => {
    let value = e;
    if (value) {
      if (new Date(startDate) <= new Date(value)) {
        setEndDate(value);
      } else {
        setEndDate(null);
      }
    }
  };

  const handleDiscount = (e) => {
    let val = e.target.value;
    const percentageValid = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;

    if (val) {
      if (percentageValid.test(val) && val != 0 && val.length >= 1) {
        setDiscountError("");
        setDiscounts(val);
      } else {
        setDiscountError("Please enter valid discount");
        setDiscounts("");
      }
    } else {
      setDiscountError("Please enter discount");
      setDiscounts("");
    }
  };

  // for create coupon API
  const getNotifcation = async () => {
    let dataBody = {
      offer_name: "admin",
      // "username" : type ? type.value : "",
      start_date: unlimited == true ? (startDate ? moment(startDate).format("YYYY-MM-DD") : "") : "",
      end_Date: unlimited == true ? (endDate ? moment(endDate).format("YYYY-MM-DD") : "") : "",
      maximum_usage: maximumUsage ? maximumUsage.value : "",
      // "coupon_code": discounts.toUpperCase() + couponCode,
      coupon_code: couponCode.toUpperCase(),
      brand_id: type ? type.value : null,
      is_expirable: unlimited,
      description: "admin",
    };

    setLoading(true)

    const res = await CreateCouponNotifcationApi(dataBody);
    if (res.code == 200) {
      showAlert("success", "Coupon Code Create Successful!");
      setType("");
      
      setMaximumUsage("");
      setMethod("");
      setStartDate("");
      setEndDate("");
      setDiscounts("");
      setCouponCode("");
      props.customActiveTab("2", false, true, false, false);
    } else if (res.data.code == 500) {
      showAlert("error", res.data.message);
    } else {
      showAlert("error", "There Is a Error Try Again");
    }
    setLoading(false)
  };

  const [filterProject, setFilterProject] = useState([]);

  // for member list API 05/12/2023 (Parth Roka)
  const memberList = async () => {
    let dataBody = {
      email: "",
      member_name: "",
      page: 1,
      limit: 1000,
    };

    const res = await memberListApi(dataBody);
    if (res.code == 200) {
      // const newArray = res.data.map(({ MemberName, _id, brands }) => ({
      //   brands: brands.map(({ name, _id }) => ({ name, client_id: _id })),
      //   MemberName,
      // }));

      const newArray = res.data.reduce((result, { MemberName, brands }) => {
        const modifiedBrandsArray = brands.map(({ name, _id }) => ({ brand_name: name, brand_id: _id, MemberName }));
        return result.concat(modifiedBrandsArray);
      }, []);
      setFilterProject(res ? newArray : []);
    }
  };

  useEffect(() => {
    memberList();
  }, []);

  useEffect(() => {
    if (props?.userListAPI == true) {
      memberList();
    }
  }, [props]);

  const usage = [];

  const CustomOption = ({ innerProps, label }) => (
    <div {...innerProps} style={{ whiteSpace: "pre-line" }}>
      {label}
    </div>
  );

  for (let i = 1; i <= 10; i++) {
    usage.push(i);
  }
  return (
    <>
      {!loading ? (
    <>
      <div className="row">
        <div className="col-md-6">
          <label className="w-100 mb-1">
            Brand Name*
            <Select
              // className='Coupon-Create-dropdown'
              id="selectCenter"
              options={filterProject && filterProject.length > 0 ? [{ label: "Brand Name", value: "" }].concat(filterProject.map((e) => ({ label: e.brand_name + ` \n - \n ${e.MemberName}`, value: e.brand_id }))) : [{ label: "Brand Name", value: "" }]}
              // options={filterProject && filterProject.length > 0 ? [{ label: 'Username', value: '' }].concat(filterProject.map(e => ({ label: e.email, value: e.email }))) : [{ label: 'Username', value: '' }]}
              isClearable={false}
              value={type}
              onChange={(e) => {
                e.value != "" ? setType(e) : setType("");
                setDiscounts(e.label);
              }}
              placeholder={"Brand Name"}
            />
          </label>
          <p className="textdanger"> {typeError}</p>
        </div>

        <div className="col-md-6">
          <label className="w-100 mb-1">
            Maximum Credit*
            <Select
              // options={filterProject && filterProject.length > 0 ? [{ label: 'Maximum Credit', value: '' }].concat(filterProject.map(e => ({ label: e.MemberName + ` \n - \n ${e.email}` , value: e.email }))) : [{ label: 'Maximum Credit', value: '' }]}
              options={usage && usage.length > 0 ? [{ label: "Maximum Credit", value: "" }].concat(usage.map((e) => ({ label: e, value: e }))) : [{ label: "Maximum Credit", value: "" }]}
              isClearable={false}
              value={maximumUsage}
              onChange={(e) => {
                e.value != "" ? setMaximumUsage(e) : setMaximumUsage("");
              }}
              placeholder={"Maximum Credit"}
              // components={{ Option: CustomOption }}
            />
          </label>
          <p className="textdanger"> {maximumUsageError}</p>
        </div>
      </div>

      <div className="row">
        <Label className="form-label mb-1">Promo Code*</Label>
        {/* <div className="col-md-2 d-flex">
          <div>
            <Input className=''
              type="text"
              value={discounts ? discounts.toUpperCase() : ""}
              disabled
              style={{ fontSize: '14px' }}
            />
          </div>
          <div>
            <label style={{ marginTop: '38px', marginLeft: '23px' }}> - </label>
          </div>

        </div> */}
        <div className="col-md-10">
          {/* <Label className="form-label mb-1" style={{ visibility: 'hidden' }}>123</Label> */}
          <Input
            type="text"
            maxLength={40}
            value={convertedValue}
            placeholder="Coupon Code*"
            onChange={(e) => {
              setCouponCode(e.target.value.toUpperCase());
            }}
          />
          <p className="textdanger"> {couponCodeError}</p>
        </div>
      </div>

      {/* minDate={moment("01/01/1947").toDate()}
       style={{backgroundColor : "#FFAF82"}}
        maxDate={endDate ? new Date(endDate) : new Date()} */}

      <div className="mb-3">
        <Input
          className="input-chck"
          type="checkbox"
          value={unlimited}
          onChange={(e) => {
            handleUnlimited(e);
          }}
        />
        <Label className="form-label ms-2 mt-1"> Time </Label>
      </div>

      <div className="row">
        {unlimited == true ? (
          <div className="col-md-6">
            <label className="w-100 mb-1">
              Start Date*
              <DatePicker
                className="date-div Coupon-Create-date"
                name="formdate"
                selected={startDate}
                onChange={(e) => (handleStartDate(e), setStartDate(e))}
                dateFormat="MM/dd/yyyy"
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                minDate={moment().toDate()}
                autoComplete="off"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                placeholderText="MM/DD/YYYY"
              />
            </label>
            <p className="textdanger"> {startDateError}</p>
          </div>
        ) : (
          ""
        )}

        {unlimited == true ? (
          <div className="col-md-6">
            <label className="w-100 mb-1">
              End Date*
              <DatePicker
                className="date-div Coupon-Create-date"
                name="formdate"
                selected={endDate}
                onChange={(e) => (handleEndDate(e), setEndDate(e))}
                dateFormat="MM/dd/yyyy"
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={moment().year() - 1900}
                minDate={startDate ? startDate : moment().toDate()}
                placeholderText="MM/DD/YYYY"
                autoComplete="off"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </label>
            <p className="textdanger"> {endDateError}</p>
          </div>
        ) : (
          ""
        )}

        <div className="col-md-12 text-center">
          {/* <button className="Coupon-Create-submit-btn" onClick={handleSubmit}>
            Submit
          </button> */}

{ loading ? (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled
                          >
                            <Spinner size="sm">Loading...</Spinner>
                            <span> Loading</span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        )}
        </div>
      </div>
    </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // marginTop: checkmargin,
        }}
      >
        <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
      </div>
    )}
  </>
  );
};

CouponCreateNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(CouponCreateNotification);
