import React from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Support = () => {
  const goSupport = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Support | BrightCheck</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Support" />
        </Container>
        <Container fluid>
          <li>
            <Link
              rel="noreferrer"
              to={`/login`}
              onClick={goSupport}
            >
              <i className="bx bx-support"></i>
              <span>Help and Support</span>
            </Link>
          </li>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Support;
