import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Label,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from 'react-select/creatable';
import { showAlert } from "components/Common/AlertMessage";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Suggestion_API, Reporting_API } from "service/notifactionService";
const  Report = () => {

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [username, setUsername] = useState(null);


  const [startErr, setStartErr] = useState("");
  const [endErr, setEndErr] = useState("");
  const [userErr, setUserErr] = useState("");

  // const [suggestions, setSuggestions] = useState([]);


  const noOptionsMessage = ({ inputValue }) => (inputValue.length >= 3 ? "No options found" : null);

  const handleInputchange = (e) => {
    if (e != "") {
      setUsername({ label: e.label, value: e.value });
    }
    if (e.length >= 3) {
      suggestionsCall(e);
    } else {
      // Clear suggestions when input length is less than 3
      setSuggestions([]);
    }
  };

 

  const loadOptions = async (inputValue, callback) => {
    var ApiData = []
    if (inputValue.length >= 3) {
      ApiData =  await suggestionsCall(inputValue)
    } 
    var data = ApiData.map((e) => ({ label: e.membername, value: e._id }))
    callback(data);
  };


  useEffect(() => {
    memberList();
  },[]);

  const [filterProject, setFilterProject] = useState([])


  const memberList = async () => {

    let dataBody = {
      "email": "",
      "member_name": "",
      "page": 1,
      "limit": 1000
    };

    const res =  await suggestionsCall("*")


      setFilterProject(res ? res : [])
    
  };


  const suggestionsCall = async (value) => {
    const res = await Suggestion_API(value);


    if (res.code == 200) {
      return res.data;
    } else {
      if (res.code == 400) {
        return [] 
      } 
      return [] 
    }
  };

  const getNotifcation = async () => {
    
    

    // setLoading(true);
    let dataBody = {
      client_id: username.value,
      from:  startDate ? moment(startDate).format('YYYY-MM-DD') : "",
      to: endDate ? moment(endDate).format('YYYY-MM-DD') : "",
    };

    const res = await Reporting_API(dataBody);

    


    window.open(res.link, '_blank');

    // if (res.code == 200) {
    //   showAlert("success", "Report Download Successfully!");
    //   // setLoading(false);
    // } else {
    //   // setLoading(false);
    //   if (res.code == 500) {
    //     showAlert("error", res?.data?.message);
    //   } else {
    //     showAlert("error", "There Is a Error Try Again");
    //   }
    // }
  };

  const handleSelect = (e) => {
    if (e.value != "") {
      setUsername(e);
      setUserErr("")
    }else{
      setUsername(null)
        setUserErr("Please select client name")
      
    }
  };

  const hadnleStartDate = (e) => {
   if(e){
setStartDate(e)
setStartErr("")
   }else{
    setStartErr("Please select start date")
    setStartDate(null)
   }
  }

  const hadnleEndDate = (e) => {
    if(e){
      setEndDate(e)
      setEndErr("")
         }else{
          setEndErr("Please select start date")
          setEndDate(null)
         }
        }
  


 const handleClick = () => {

  if(startDate == null || startDate == ""){
setStartErr("Please select start date")
  }else{
    setStartErr("")
  }

  if(endDate == null || endDate == ""){
    setEndErr("Please select end date")
  }else{
    setEndErr("")
  }

  if(username == null){
    setUserErr("Please select client name")
  }

  if(endDate && startDate && username != null){
    getNotifcation()
  }

  }

  const handleClear = () => {
    setEndDate("");
    setStartDate("")
    setUsername(null);
  }


  const fiftyYearsAgo = moment().subtract(50, 'years').year();
const yearsList = Array.from({ length: 50 }, (_, index) => fiftyYearsAgo + index);

  return (
    <>
    <div className="page-content" style={{minHeight:'100vh'}}>
      <MetaTags>
        <title> Report | BrightCheck</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs breadcrumbItem="Notification Settings" />
        <Row>
          <Col lg={12}>
          <div className="row">



          <div className="col-4 mb-3">
                <Label className="form-label">Client Name</Label>
                {/* <CreatableSelect
                id="selectCenter"
              options={suggestions && suggestions.length > 0 ? suggestions.map((e) => ({ label: e.membername, value: e._id })) : []}
              noOptionsMessage={noOptionsMessage}
                value={username}
                placeholder={"Client Name"}
                styles={customStyles}
                onInputChange={(e) => handleInputchange(e)}
                onChange={(e) => handleSelect(e)}
              /> */}

{/* <AsyncSelect noOptionsMessage={noOptionsMessage} value={username} cacheOptions onChange={(e) => handleSelect(e)} isClearable={true} loadOptions={loadOptions} defaultOptions /> */}
            
<Select
              // className='Coupon-Create-dropdown'
              id='selectCenter'
              options={filterProject && filterProject.length > 0 ? [{ label: 'Brand Name', value: '' }].concat(filterProject.map(e => ({ label: e.membername, value: e._id }))) : [{ label: 'Brand Name', value: '' }]}
              // options={filterProject && filterProject.length > 0 ? [{ label: 'Username', value: '' }].concat(filterProject.map(e => ({ label: e.email, value: e.email }))) : [{ label: 'Username', value: '' }]}
              isClearable={false}
              value={username} 
              onChange={(e) => { handleSelect(e) }}
              placeholder={"Brand Name"} />
            
             <p className='textdanger'>{userErr}</p>
              </div>

              <div className="col-4 mb-3">
                <Label className="form-label">Start Date</Label>
                <DatePicker
                  className="form-control "
                  selected={startDate}
                  onChange={(e) => { hadnleStartDate(e)}}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  // isClearable={true}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={moment().year() - 1900}

                  // minDate={moment("01/01/1947").toDate()}
                  // maxDate={endDate ? new Date(endDate) : null}

                  maxDate={endDate ? new Date(endDate) : moment("12/31/2050").toDate()}
                  minDate={moment("01/01/2000").toDate()}
                  autoComplete="off"
                  placeholderText="MM/DD/YYYY"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <p className='textdanger'>{startErr}</p>
              </div>

             

              <div className="col-4 mb-3">
                <Label className="form-label">End Date</Label>
                <DatePicker
                  className="form-control "
                  selected={endDate}
                  onChange={(e) => hadnleEndDate(e)}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={1900}
                  // minDate={startDate}
                  // maxDate={startDate ? new Date(endDate) : null}

                  minDate= {startDate ? startDate : moment("01/01/2000").toDate()}
                  maxDate={moment("12/31/2050").toDate()}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  placeholderText="MM/DD/YYYY"
                />
                <p className='textdanger'>{endErr}</p>
              </div>
              <div className="col-md-12">
                <div className="text-center">
              <button className="btn btn-primary m-auto " onClick={() => handleClear()}>Clear</button>
              <button className="btn btn-primary m-auto ms-3 " onClick={() => handleClick()}>Download</button>
              </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>  
  </>
  )
}

export default Report
