import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { CouponListNotifcationApi } from "service/notifactionService";
import { CouponStatusApi } from "service/notifactionService";
import moment from "moment";
import linkIcon from '../Notification/linkIcon.png';
import { showAlert } from "components/Common/AlertMessage";

// Added by Parth Roka
const CouponListNotification = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [couponStatusLabel, setCouponStatusLabel] = useState(null);
  const [flage, setFlage] = useState(false);
  const [field, setField] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkmargin, setCheckMargin] = useState("0px");
  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [status, setstatus] = useState('no');

  useEffect(() => {
    if (props?.flageAPI == true) {
      
      getOrderAll();
    }
    setStartDate(props?.startDate ? moment(props?.startDate).format('YYYY-MM-DD') : "")
    setEndDate(props?.endDate ? moment(props?.endDate).format('YYYY-MM-DD') : "")
    setCouponCode(props?.couponCode)
    setCouponStatus(props?.couponStatus)
    setCouponStatusLabel(props?.couponStatusLabel)
    setFlage(props?.flage)
    setField(props?.field)
  }, [props]);

  useEffect(() => {
    if (field.length == 0 && couponCode.length == 0 && !startDate && !endDate && !couponStatus) {
      getOrderAll();
    }
  }, []);

  useEffect(() => {
    if (couponStatusLabel) {
      if (couponStatusLabel.label === "Coupon Status") {
        getOrderAll();
      }
    }
    if (field.length > 0 || couponCode.length > 0) {
      getOrderAll();
    }
    if (startDate || startDate == "" || endDate || endDate == "" || couponStatus) {
      getOrderAll();
    }

    if (couponCode) {
      getOrderAll();
    }
  }, [startDate, endDate, couponCode, couponStatus, field, couponStatusLabel]);

  useEffect(() => {
    if (currentPage >= 1) {
      getOrderAll();
    }
  }, [currentPage]);

  useEffect(() => {
    if (couponCode == "") {
      getOrderAll();
    }
  }, [couponCode]);

  const getOrderAll = async (pageNo) => {
    setLoading(true)
    const jsonData = {
      "from": startDate ? startDate : "",
      "to": endDate ? endDate : "",
      "coupon_code": couponCode ? couponCode : "",
      "couponStatus": couponStatus ? couponStatus : "",
      "page": currentPage,
      "limit": 10
    }
    if (flage == false) {
      const resp = await CouponListNotifcationApi(jsonData);
      if (resp.code == 200) {
        setLoading(false);
        setData(resp.data);
        setTotalPages(resp.totalPages);
      } else {
        setCheckMargin("0px");
        setData("");
        setLoading(false);
      }
    }

  };

  // Added by Nehal Vasoya (17-04-2024)
  const handleStatus = async (e, row) => {
    let jsonData = {
      "coupon_id": row._id,
      "status": e.target.checked == true ? "active" : "inactive"
    }
    setLoading(true)
    const resp = await CouponStatusApi(jsonData);
    if (resp.code == 200) {
      setLoading(false);
    //   if(jsonData.status==='active'){
    //   showAlert("success", `Status ${jsonData.status}`);
    // }else{
    //   showAlert("error", `Status ${jsonData.status}`);
    //   }
      getOrderAll();
    } else {
      setLoading(false);
    }
  }

  const toggle = () => {
    setModal(!modal);
  };
  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "coupon_code",
      text: "COUPON CODE",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <p className="text-gray">
              {row.coupon_code} / <img title={row.url} src={linkIcon} style={{ height: "15px", cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(row.url).then(() => {
                showAlert("success", "Link Copied To Clipboard ");
              })
                .catch((error) => {
                  showAlert("error", "Copy failed!");
                })
              } />
            </p>
          </>
        )
      }
    },
    {
      dataField: "clientName",
      text: "CLIENT NAME",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">
            {row.clientName}
          </p>
        </>
      ),
    },
    {
      dataField: "start_date",
      text: "START DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{row?.start_date ? moment(row?.start_date).local().format("MM / DD / YYYY") : "-"}</p>
          {/* <p>{moment(row?.createdAt).local().format("hh:mm:ss a")}</p> */}
        </>
      ),
    },
    {
      dataField: "end_Date",
      text: "END DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{row?.start_date ? moment(row?.end_Date).local().format("MM / DD / YYYY") : "-"}</p>
          {/* <p>{moment(row?.end_Date).local().format("hh:mm:ss a")}</p> */}
        </>
      ),
    },
    {
      dataField: "maximum_usage",
      text: "CREDIT",
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">
            <i>{row.maximum_usage || ""}</i>
          </p>
        </>
      ),
    },
    {
      dataField: "clicks",
      text: "CLICKS",
      formatter: (cellContent, row) => {
        return (
          <>
            <p className="text-gray">
              <i>{row.clicks}</i>
            </p>
          </>
        );
      },
    },
    {
      dataField: "installs",
      text: "INSTALLS",
      formatter: (cellContent, row,) => (
        <>
          <p className="text-gray">
            <i>{row.installs}</i>
          </p>
        </>
      ),
    },
    //added by (Nehal Vasoya) 
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row, index) => (
        <div className="float-start">

          {/* {row.status === "expired" ?  (
            <span
              style={{
                borderRadius: "38px",
                padding: "7px 21px",
                fontWeight: "bold",
                backgroundColor: "#ff7373",
                color: "#262626",
                fontSize: "13px",
              }}
            >
              {row.status}
            </span>
          ) : (
            <div className="form-check d-flex justify-content-end form-switch form-switch-lg mb-3 Coupon-table-switch">
          <input
            type="checkbox"
            className="form-check-input"
            id={`customSwitchsizelgs${index}`}
            checked={row.status == "active" ? true : false}
            onChange={(e) => { handleStatus(e, row)}}
          />
          <label
            className={row.status == "active" ? "form-check-label checked" : "form-check-label"}
            htmlFor={`customSwitchsizelgs${index}`}
          >
             {row.status === "active" ? "Active" : "Inactive"}
              </label>

            </div>
          )} */}

          {row.status === "expired" ? (
            <span
              style={{
                borderRadius: "38px",
                padding: "7px 21px",
                fontWeight: "bold",
                backgroundColor: "#ff7373", // Red background for expired status
                color: "#262626", // Dark text for readability
                fontSize: "13px",
              }}
            >Expired
            </span>
          ) : (
            <div className="form-check d-flex justify-content-end form-switch ps31 form-switch-lg mb-3 Coupon-table-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id={`customSwitchsizelgs${index}`}
                checked={row.status === "active"}
                onChange={(e) => handleStatus(e, row)}
              />
              <label
                className={row.status === "active" ? "form-check-label checked" : "form-check-label"}
                htmlFor={`customSwitchsizelgs${index}`}
              >
                {row.status === "active" ? "Active" : "Inactive"}
              </label>
            </div>
          )}

        </div>
      ),
    }
  ];
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <>
            {!loading ? (
              <>
                <React.Fragment>
                  <Row >
                    <Col xl="12">
                      <div className="table-responsive orders-table-main-div" id="harshu">
                        <BootstrapTable
                          keyField="id"
                          data={data}
                          columns={EcommerceOrderColumns(toggle)}
                          responsive
                          bordered={false}
                          striped={false}
                          classes={
                            "table align-middle table-nowrap table-no-check table-check"
                          }
                          headerWrapperClasses={"table-light"}

                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
                {totalPages >= 1 ?
                  data && data.length > 0 ?
                    <div className="pagination pagination-rounded justify-content-center mt-4">
                      <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                        {'<<'}
                      </button>
                      <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                        {'<'}
                      </button>
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(
                        (page) => (
                          <button
                            key={page}
                            onClick={() => handlePageClick(page)}
                            disabled={currentPage === page}
                            className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn btn-light  btn-xl rounded-circle m-2'}
                          >
                            {page}
                          </button>
                        )
                      )}
                      {totalPages > startPage + 4}
                      <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                        {'>'}
                      </button>
                      <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                        {'>>'}
                      </button>
                    </div>
                    : (<div style={{ textAlign: "center" }}>
                      <p> No Data Found</p>{" "}
                    </div>
                    )
                  : ""}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: checkmargin,
                }}
              >
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              </div>
            )}
          </>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CouponListNotification.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(CouponListNotification);
