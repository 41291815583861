import React, { useState, useEffect } from "react";
import "../Coupon/index.css";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";
import { Card, CardBody, Col, Container, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Spinner } from "reactstrap";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import CouponListNotification from "components/Notification/CouponListNotification";
import { updatedAdminTemplates } from "service/notifactionService";
import CouponCreateNotification from "components/Notification/CouponCreateNotification";
import CouponLogsNotification from "components/Notification/CouponLogsNotification";
import CreditUsageLogs from "components/Notification/CreditUsageLogs";
import { showAlert } from "components/Common/AlertMessage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import UsernameCreateNotification from "components/Notification/UsernameCreateNotification";

import { CouponCountAPI } from "service/notifactionService";

// Added by Parth Roka
const CouponNotifications = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [couponStatusLabel, setCouponStatusLabel] = useState(null);
  const [field, setField] = useState("");
  const [flage, setFlage] = useState(false);
  const [flageAPI, setFlageAPI] = useState(false);
  const [flageOther, setFlageOther] = useState(true);
  const [customActiveTab, setcustomActiveTab] = useState("4");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [userListAPI, setUserListAPI] = useState(false);
  const [countData, setCountData] = useState("");

  const [selectName, setSelectName] = useState("");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  

  const handleApidata = async () => {
    let resp = await CouponCountAPI();
    if (resp.code == 200) {
      setCountData(resp);
    } else {
      // setShowData(reports);
    }
  };

  useEffect(() => {
    handleApidata();
  }, []);

  const handleUpdatedCheck = async (type, id, checktype) => {
    setLoading(true);
    setType(checktype);
    let dataBody = {
      status: type,
    };
    const res = await updatedAdminTemplates(id, dataBody);

    if (res.code == 200) {
      showAlert("success", "Notification Updated Successfully!");
    } else {
      setLoading(false);
      setType("");
      showAlert("error", "There Is a Error Try Again");
    }
  };

  function handleCustomeActiveTab(id, flage, flageAPI, FlageOther, userlist) {
    setFlage(flage);
    setFlageAPI(flageAPI);
    setFlageOther(FlageOther);
    setUserListAPI(userlist);
    setTimeout(() => {
      setFlageAPI(false);
    }, 3000);
    setcustomActiveTab(id ? id : "4");
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Coupon | BrightCheck</title>
        </MetaTags>
        <Container fluid={true}>
          {flageOther == true ? null : selectName == "usage" || selectName == "claimed" || selectName == "coupon" ? (
            <Row>
              <Col xl="12">
                <Row>
                  <Col md={selectName == "claimed" ? "3" : "4"}>
                    <Card className="mini-stats-wid home-card23">
                      <CardBody>
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              {selectName == "claimed" ? <i className={"mdi mdi-newspaper font-size-24"}></i> : ""}
                              {selectName == "usage" ? <i className={"mdi mdi-cached font-size-24"}></i> : ""}
                              {selectName == "coupon" ? <i className={"mdi mdi-newspaper font-size-24"}></i> : ""}
                            </span>
                          </div>
                          <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                            {selectName == "claimed" ? "Total Claim Count" : ""}
                            {selectName == "usage" ? "Total Usable Credit Count" : ""}
                            {selectName == "coupon" ? "Total Coupons" : ""}
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h1 className="mb-0">
                            {selectName == "claimed" ? countData?.claim_credit?.totalClaimCount : ""}
                            {selectName == "usage" ? countData?.credit_usage?.totalUsableCreditCount : ""}
                            {selectName == "coupon" ? countData?.coupon?.totalCoupons : ""}
                          </h1>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={selectName == "claimed" ? "3" : "4"}>
                    <Card className="mini-stats-wid home-card23">
                      {/* <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-user-circle font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                        {selectName == "claimed" ?  "Android Claim Count" : ""}
                          {selectName == "usage" ? "Android Used Credit Count"  : ""}
                          {selectName == "coupon" ? "Active Coupons" : ""}
                        </p>
                      </div>
                      <div className="flex-grow-1">
                      <h1 className="mb-0">
                      {selectName == "claimed" ? countData?.claim_credit?.androidClaimCount : ""}
                          {selectName == "usage" ? countData?.credit_usage?.androidUsedCreditCount : ""}
                          {selectName == "coupon" ? countData?.coupon?.activeCoupons : ""}
                      </h1>
                      </div>
                    </CardBody> */}

                      {/* added b mansi */}
                      <CardBody>
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              {selectName == "claimed" ? <i className={"mdi mdi-android font-size-24"}></i> : ""}
                              {selectName == "usage" ? <i className={"mdi mdi-check-underline font-size-24"}></i> : ""}
                              {selectName == "coupon" ? <i className={"mdi mdi-close-circle-outline font-size-24"}></i> : ""}
                            </span>
                          </div>
                          <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                            {selectName == "claimed" ? "Android Claim Count" : ""}
                            {selectName == "usage" ? "Used Credit Count" : ""}
                            {selectName == "coupon" ? "Expired Coupons" : ""}
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h1 className="mb-0">
                            {selectName == "claimed" ? countData?.claim_credit?.androidClaimCount : ""}
                            {selectName == "usage" ? countData?.credit_usage?.usedCreditCount : ""}
                            {selectName == "coupon" ? countData?.coupon?.expiredCoupons : ""}
                          </h1>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={selectName == "claimed" ? "3" : "4"}>
                    <Card className="mini-stats-wid home-card23">
                      <CardBody>
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              {selectName == "claimed" ? <i className={"mdi mdi-apple font-size-24"}></i> : ""}
                              {selectName == "usage" ? <i className={"mdi mdi-apple font-size-24"}></i> : ""}
                              {selectName == "coupon" ? <i className={"mdi mdi-newspaper-minus font-size-24"}></i> : ""}
                            </span>
                          </div>
                          <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                            {selectName == "claimed" ? "iOS Claim Count" : ""}
                            {selectName == "usage" ? "iOS Used Credit Count" : ""}
                            {selectName == "coupon" ? "Inactive Coupons" : ""}
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h1 className="mb-0">
                            {selectName == "claimed" ? countData?.claim_credit?.iOSClaimCount : ""}
                            {selectName == "usage" ? countData?.credit_usage?.iOSUsedCreditCount : ""}
                            {selectName == "coupon" ? countData?.coupon?.inactiveCoupons : ""}
                          </h1>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={selectName == "claimed" ? "3" : "4"}>
                    <Card className="mini-stats-wid home-card23">
                      {/* <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bxs-user-badge font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                        {selectName == "claimed" ?  "totalClaim Count" : ""}
                          {selectName == "usage" ? "Used Credit Count"  : ""}
                          {selectName == "coupon" ? "Expired Coupons" : ""}
                        </p>
                      </div>
                      <div className="flex-grow-1">
                      <h1 className="mb-0">
                      {selectName == "claimed" ? countData?.claim_credit?.totalClaimCount : ""}
                          {selectName == "usage" ? countData?.credit_usage?.usedCreditCount : ""}
                          {selectName == "coupon" ? countData?.coupon?.expiredCoupons : ""}
                      </h1>
                      </div>
                    </CardBody> */}

                      {/* added by mansi */}
                      <CardBody>
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              {selectName == "claimed" ? <i className={"mdi mdi-web font-size-24"}></i> : ""}
                              {selectName == "usage" ? <i className={"mdi mdi-android font-size-24"}></i> : ""}
                              {selectName == "coupon" ? <i className={"mdi mdi-newspaper-plus font-size-24"}></i> : ""}
                            </span>
                          </div>
                          <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                            {selectName == "claimed" ? "Web Claim Count" : ""}
                            {selectName == "usage" ? "Android Used Credit Count" : ""}
                            {selectName == "coupon" ? "Active Coupons" : ""}
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h1 className="mb-0">
                            {selectName == "claimed" ? countData?.claim_credit?.webClaimCount : ""}
                            {selectName == "usage" ? countData?.credit_usage?.androidUsedCreditCount : ""}
                            {selectName == "coupon" ? countData?.coupon?.activeCoupons : ""}
                          </h1>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {selectName == "claimed" ? (
                    ""
                  ) : (
                    <Col md={selectName == "coupon" ? "4" : "4"}>
                      <Card className="mini-stats-wid home-card23">
                        <CardBody>
                          <div className="d-flex align-items-center mb-2">
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                {selectName == "usage" ? <i className={"mdi mdi-web font-size-24"}></i> : ""}
                                {selectName == "coupon" ? <i className="mdi mdi-download-circle-outline font-size-24"></i> : ""}
                              </span>
                            </div>
                            <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                              {selectName == "usage" ? "Web Used Credit Count" : ""}
                              {selectName == "coupon" ? "App Installs" : ""}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            {selectName == "coupon" ? 
                            <h1 className="mb-0">
                              {selectName == "coupon" ? <i className={"bx bxl-android text-muted font-size-32 pt6"}></i> : ""}
                              {selectName == "coupon" ? countData?.coupon?.androidinstallCount : ""}
                              </h1> : "" }
                              <h1 className="mb-0">
                              {selectName == "coupon" ? <i className={"mdi mdi-apple text-muted font-size-32"}></i> : ""}
                              {selectName == "coupon" ? countData?.coupon?.iosinstallCount : ""}
                              {selectName == "usage" ? countData?.credit_usage?.webUsedCreditCount : ""}
                            </h1>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {selectName == "coupon" ? (
                    <Col md={selectName == "coupon" ? "4" : ""}>
                      <Card className="mini-stats-wid home-card23">
                        <CardBody>
                          <div className="d-flex align-items-center mb-2">
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i className="mdi mdi-link-variant-plus font-size-24"></i>
                              </span>
                            </div>
                            <p className="text-muted fw-medium mb-0 ms-2  fw-bold">Links Clicks</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <h1 className="mb-0">
                              {selectName == "coupon" ? <i className={"bx bxl-android text-muted font-size-32 pt6"}></i> : ""}
                              {selectName == "coupon" ? countData?.coupon?.androidiclickCount : ""}
                              </h1>
                              <h1 className="mb-0">
                              {selectName == "coupon" ? <i className={"mdi mdi-apple text-muted font-size-32"}></i> : ""}
                              {selectName == "coupon" ? countData?.coupon?.iosclickCount : ""}
                            </h1>
                            <h1 className="mb-0">
                              {selectName == "coupon" ? <i className={"mdi mdi-web text-muted font-size-32"}></i> : ""}
                              {selectName == "coupon" ? countData?.coupon?.webclickCount : ""}
                            </h1>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Breadcrumbs breadcrumbItem="Notification Settings" />
          {flageOther == true ? null : (
            <div className="row">
              <div className="col-4 mb-3">
                <Label className="form-label">Start Date</Label>
                <DatePicker
                  className="form-control "
                  selected={startDate}
                  onChange={(e) => setStartDate(e)}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={moment("01/01/1947").toDate()}
                  maxDate={endDate ? new Date(endDate) : null}
                  autoComplete="off"
                  placeholderText="MM/DD/YYYY"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  // customInput={<ReactInputMask mask="99/99/9999" />}
                />
              </div>

              <div className="col-4 mb-3">
                <Label className="form-label">End Date</Label>
                <DatePicker
                  className="form-control "
                  selected={endDate}
                  onChange={(e) => setEndDate(e)}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={moment().year() - 1900}
                  minDate={startDate}
                  // maxDate={startDate ? new Date() : null}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  // customInput={<ReactInputMask mask="99/99/9999" />}
                  placeholderText="MM/DD/YYYY"
                />
              </div>

              {flage == true ? null : (
                <div className="col-4 mb-3">
                  <Label className="form-label">Coupon Status</Label>
                  <Select
                    placeholder={
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        All Status
                      </span>
                    }
                    value={couponStatusLabel || null}
                    onChange={(e) => {
                      setCouponStatus(e.value);
                      setCouponStatusLabel(e);
                    }}
                    options={[
                      { label: "All Status", value: "" },
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                      { label: "Expire", value: "expired" },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              )}

              <div className="col-4 mb-3">
                <Label className="form-label">Coupon Code</Label>
                <DebounceInput maxLength={40} value={couponCode} debounceTimeout={300} minLength={2} name="filterCustomer" onChange={(e) => setCouponCode(e.target.value.toUpperCase())} className="form-control field-search" placeholder="Coupon Code" />
              </div>
              {flage == true ? (
                <div className="col-4 mb-3">
                  <Label className="form-label">Field</Label>
                  <DebounceInput maxLength={40} debounceTimeout={300} minLength={2} name="filterCustomer" onChange={(e) => setField(e.target.value)} className="form-control field-search" placeholder="By ID, email or phone" />
                </div>
              ) : null}
            </div>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                          setFlage(false);
                          setFlageOther(true);
                          setSelectName("add");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Add Member</b>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          // toggleCustom("1"); setFlage(false); setFlageOther(false); setSelectName("coupon");
                          toggleCustom("1");
                          setFlage(false);
                          setFlageOther(true);
                          setSelectName("create");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Create Coupon</b>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <span className="d-none d-sm-block border-part-new"></span>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                          setFlage(false);
                          setFlageOther(false);
                          setSelectName("coupon");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Coupon List</b>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                          setFlage(true);
                          setFlageOther(false);
                          setSelectName("claimed");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Claimed Credit Logs</b>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleCustom("5");
                          setFlage(true);
                          setFlageOther(false);
                          setSelectName("usage");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Credit Usage Logs</b>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="py-4 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Create Coupon</h5>
                        </Col>
                        <Col sm="12">
                          {loading && type === "userSms" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                            </div>
                          ) : (
                            <CouponCreateNotification handleUpdatedCheck={handleUpdatedCheck} customActiveTab={handleCustomeActiveTab} userListAPI={userListAPI} />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Claimed Credit Logs</h5>
                        </Col>
                        <Col sm="12">
                          {loading && type === "userSms" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                            </div>
                          ) : (
                            <CouponLogsNotification startDate={startDate ? startDate : ""} endDate={endDate ? endDate : ""} couponCode={couponCode ? couponCode : ""} couponStatus={couponStatus ? couponStatus : ""} couponStatusLabel={couponStatusLabel ? couponStatusLabel : ""} field={field ? field : ""} flage={flage} />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Coupon List</h5>
                        </Col>
                        <Col sm="12" className="mb-4">
                          <CouponListNotification startDate={startDate ? startDate : ""} endDate={endDate ? endDate : ""} couponCode={couponCode ? couponCode : ""} couponStatus={couponStatus ? couponStatus : ""} couponStatusLabel={couponStatusLabel ? couponStatusLabel : ""} field={field ? field : ""} flage={flage} flageAPI={flageAPI} />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Add Member *</h5>
                        </Col>
                        <Col sm="12" className="mb-4">
                          <UsernameCreateNotification customActiveTab={handleCustomeActiveTab} />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Credit Usage Logs</h5>
                        </Col>
                        <Col sm="12">
                          {loading && type === "userSms" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                            </div>
                          ) : (
                            <CreditUsageLogs startDate={startDate ? startDate : ""} endDate={endDate ? endDate : ""} couponCode={couponCode ? couponCode : ""} couponStatus={couponStatus ? couponStatus : ""} couponStatusLabel={couponStatusLabel ? couponStatusLabel : ""} field={field ? field : ""} flage={flage} />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CouponNotifications;
