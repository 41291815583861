import React, { useRef, useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { uplodeImage, getSingleServiceData, updateService } from "../../service/pageService";
import { useHistory, useParams } from "react-router-dom";
import { showAlert } from "components/Common/AlertMessage";
import { CKEditor } from 'ckeditor4-react';

const EditService = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
    price: "",
    image: "",
    benefit: "",
    description: "",
  });
  const [name, setName] = useState("");
  const [benefit, setBenefit] = useState("");
  const [rufImge, setRufImage] = useState("");
  const history = useHistory();
  const pic =
    "https://media.istockphoto.com/vectors/no-photo-available-vector-icon-default-image-symbol-picture-coming-vector-id1354776450?s=612x612";
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [picLoading, setPicLoading] = useState(false);

  useEffect(() => {
    handleApiCall();
  }, [id]);

  const handleApiCall = async () => {
    setLoading(true);
    let resp = await getSingleServiceData(id);
    if (resp.code == 200) {
      setLoading(false);
      setPicLoading(true);
      setImage(`${process.env.REACT_APP_API_URL}/${resp.data.image}`);
      setName(resp.data.name);
      setData(resp.data);
      setBenefit(resp.data.benefit);
      setRufImage(resp.data.image);
      setPicLoading(false);
    }
  };
  const HandleInputField = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleFileChange = (event) => {
    if (!event.target.files[0].type.includes("image")) {
      return showAlert("error", "Please select only images.");
    }

    if (event.target.files[0].size > 2 * 1024 * 1024) {
      return showAlert("error", "Image size must be less than 2 mb.");
    }
    const fileObj = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
    };
    reader.readAsDataURL(fileObj);
    if (!fileObj) {
      return;
    }
    setImageObject(fileObj);
  };

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleSubmitChange = async () => {
    let value = {
      name: data.name,
      price: data.price,
      image: data.image,
      benefit,
      description: data.description,
    };
    setLoading(true);
    let img = null;
    if (imageObject) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", imageObject);
      let imageurl = await uplodeImage(bodyFormData);
      img = imageurl.data.path;
    }
    if (img) {
      value.image = img;
    } else {
      value.image = rufImge;
    }
    const resp = await updateService(id, value);
    if (resp.code == 200) {
      setLoading(false);
      showAlert("success", "Content page updated successfully");
      history.push("/services");
    } else {
      setLoading(false);
      showAlert(
        "error",
        resp.error.response.data.message || "There Is a error try again"
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Services | BrightCheck </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {name ? (
            <Row xs={"2"}>
              <Breadcrumbs
                title="Dashboards"
                breadcrumbItem={`Service Editor For ${name} `}
              />
              <h5 className="text-end field-text1">
                {/* <b>SERVICE</b> {` / ${name}`} */}
              </h5>
            </Row>
          ) : (
            ""
          )}
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              {!loading ? (
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();

                    return false;
                  }}
                >
                  <Row>
                    <Col sm={8}>
                      <Row>
                        <Col sm="12" className="mb-3">
                          <Label className="form-label">Name</Label>
                          <Input
                            value={data.name}
                            name="name"
                            className="form-control"
                            placeholder="Enter Name"
                            type="text"
                            onChange={HandleInputField}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="mb-3">
                          <Label className="form-label">Price</Label>
                          <Input
                            value={data.price}
                            name="price"
                            className="form-control"
                            placeholder="Enter Price"
                            type="text"
                            onChange={HandleInputField}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="mb-3">
                          <div className="textarea-content border-bottom-gray2 pb-4 description">
                            <Label className="form-label">Description</Label>
                            <Input
                              rows="5"
                              value={data.description}
                              name="description"
                              className="form-control"
                              placeholder="Enter description"
                              type="textarea"
                              onChange={HandleInputField}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="mb-3">
                          <div className="textarea-content border-bottom-gray2 pb-4">
                            <Label className="form-label">Benefit</Label>
                            {benefit &&
                              <CKEditor initData={benefit} onChange={(event) => setBenefit(event.editor.getData())} />}
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={4}>
                      <Row>
                        <Col sm="12" className="mb-3">
                          {picLoading ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <div className="profile-picture24 ">
                              <img
                                src={image == "" ? pic : image}
                                alt="picture new"
                                crossOrigin="anonymous"
                                className="img-fluid w-100"
                              />
                            </div>
                          )}
                          <div className="upload-profile d-flex align-items-start mt-3">
                            <input
                              style={{ display: "none" }}
                              ref={inputRef}
                              type="file"
                              accept="image/png, image/jpeg"
                              onChange={handleFileChange}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary me-3"
                              onClick={handleClick}
                            >
                              Upload
                            </button>
                            <p className="text-gray">
                              Accepted formats: JPG, PNG Your image must be up
                              to 512x512px and cannot be over 2MB.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <div className="form-action pt-3">
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={handleSubmitChange}
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          history.push("/services");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Row>
                </Form>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditService;
