import axios from "axios";
// Added by Moinuddin Mansuri 31/01/2024
const Suggestion_API = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/coupon/client/search`,
      {
        params: {
          query: dataBody,
        },
      },
      {
        headers: { Authorization: token },
      }
    );

    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   // throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};
const Reporting_API = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/coupon/exp/rpt`,
      { params: dataBody },
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};

// Added by Parth Roka 05/12/2023
const createMember = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_URL}/admin/coupon/member/create`,
      dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   // throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};
// Added by Parth Roka 05/12/2023
const memberListApi = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/member/list`,
      dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka
const CreateCouponNotifcationApi = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/create`,
      dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};

// Added by Parth Roka
const CouponLogsNotifcationApi = async (dataBody) => {
  let token = localStorage.getItem("token");

  // url: `${process.env.REACT_APP_API_URL}/admin/coupon/logs` ,
  // `http://192.168.0.180:5000/admin/coupon/logs`

  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/logs/claim`,
      dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Nehal vasoya (17-04-2024)
const CreditUsageAPI = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/logs/usage`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Moinuddin Mansuri
const CouponCountAPI = async (jsonData) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/counts`,
      jsonData,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka
const CouponListNotifcationApi = async (jsonData) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/list`,
      jsonData,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};
// Added by Moinuddin Mansuri
const DeeplinkAddApi = async (jsonData) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/trenta/partner`,
      jsonData,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Moinuddin Mansuri
const getClientDetailsApi = async (page) => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin/trenta/partner/10/${page}`,
      method: "GET",
    };
    let res = await axios(config);
    // return { status: true, data: res.data.data, code: res.data.code };
    if (res.data.code == 200) {
      return res.data;
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Moinuddin Mansuri
const CreateLinkAPI = async (jsonData, ID) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/trenta/generate/${ID}`,
      jsonData,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Moinuddin Mansuri
const DeeplinkEditApi = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_URL}/admin/trenta/partner`,
      dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   // throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};

// Added by Moinuddin Mansuri
const selectClientApi = async (name) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/trenta/partner/10/1`,
      {
        params: {
          name: name,
        },
        headers: { Authorization: token },
      }
    );

    if (resp.data.code === 200) {
      return resp.data;
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

const convertToISO = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toISOString();
  }
};
// Added by Moinuddin Mansuri
const LinkListAPI = async (id, time, page) => {
  let isoString = "";
  if (time != "") {
    isoString = convertToISO(time ? time : "");
  }

  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/trenta/promocode/10/${page}?id=${id}&time=${isoString}`,
      {
        // params: {
        //  id: id,
        //  time: time,
        // },
        headers: { Authorization: token },
      }
    );

    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   // throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};

// Added by Moinuddin Mansuri
const PromoDownloadAPI = async (id, download) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/trenta/promocode/links`,
      {
        params: {
          id: id,
          download: download,
        },
        headers: { Authorization: token },
      }
    );

    if (resp.data.code == 200) {
      return resp.data;
    }
    // else {
    //   // throw new Error(resp);
    // }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
    // return {
    //   data: "",
    //   error: error,
    //   status: 400,
    // };
  }
};

// Added by Moinuddin Mansuri
const PromoCodeDeepAPI = async (name) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/trenta/promocode`,
      {
        headers: { Authorization: token },
      }
    );

    if (resp.data.code === 200) {
      return resp.data;
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Mansi Vaghela
const FeedbackGetAPI = async (data) => {
  debugger;
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/survey/get`,
      data,
      {
        headers: { Authorization: token },
      }
    );

    if (resp.data.code === 200) {
      return resp.data;
    }
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Parth Roka
const updatedAdminMaintance = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/system/status/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka
const getMaintanceNotifcationApi = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin/system/getsettings`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Parth Roka
const getNotifcationApi = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin-notification/get-all`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

const getAdminTemplates = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin-notification/gettamplate`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

const updatedAdminTemplates = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin-notification/update/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};
const CouponStatusApi = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/coupon/status`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

export {
  CouponStatusApi,
  Suggestion_API,
  CreateLinkAPI,
  PromoDownloadAPI,
  getClientDetailsApi,
  LinkListAPI,
  PromoCodeDeepAPI,
  selectClientApi,
  DeeplinkEditApi,
  DeeplinkAddApi,
  Reporting_API,
  createMember,
  memberListApi,
  CouponLogsNotifcationApi,
  CreditUsageAPI,
  CouponCountAPI,
  CreateCouponNotifcationApi,
  getAdminTemplates,
  getNotifcationApi,
  updatedAdminTemplates,
  getMaintanceNotifcationApi,
  updatedAdminMaintance,
  CouponListNotifcationApi,
  FeedbackGetAPI,
};
