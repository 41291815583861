import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { CouponLogsNotifcationApi } from "service/notifactionService";
import moment from "moment";
import linkIcon from "../Notification/linkIcon.png";
import { showAlert } from "components/Common/AlertMessage";
import { decryptData } from "../../Encryption/encryption";
import RemoveICon from "./../../assets/icons/remove-icon.svg";
import CouponLogsModel from "./CouponLogsModel";

// Added by Parth Roka
const CouponLogsNotification = (props) => {
  const [orderfilter, setOrderfilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkmargin, setCheckMargin] = useState("0px");
  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [startPage, setStartPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [flage, setFlage] = useState(false);
  const [field, setField] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showData, setShowData] = useState({});
  useEffect(() => {
    setStartDate(props?.startDate);
    setEndDate(props?.endDate);
    setCouponCode(props?.couponCode);
    setCouponStatus(props?.couponStatus);
    setFlage(props?.flage);
    setField(props?.field);
  }, [props]);

  useEffect(() => {
    getOrderAllSecond();
    if (field.length == 0 && couponCode.length == 0 && startDate == "" && endDate == "" && couponStatus == "") {
      getOrderAll();
    }
  }, []);

  const getOrderAllSecond = async (pageNo) => {
    const jsonData = {
      from: startDate ? startDate : "",
      to: endDate ? endDate : "",
      coupon_code: couponCode ? couponCode : "",
      couponStatus: couponStatus ? couponStatus : "",
      flied: field ? field : "",
      page: currentPage,
      limit: 10,
    };
    const resp = await CouponLogsNotifcationApi(jsonData);

    if (resp.code == 200) {
      setData(resp.data);
      setTotalPages(resp.totalPages);
    } else {
      setCheckMargin("0px");
    }
  };

  useEffect(() => {
    if (field.length > 0 || couponCode.length > 0) {
      getOrderAll();
    }
    if (startDate || endDate || couponStatus) {
      getOrderAll();
    }
  }, [startDate, endDate, couponCode, couponStatus, field]);

  const getOrderAll = async (pageNo) => {
    setLoading(true)
    const jsonData = {
      from: startDate ? startDate : "",
      to: endDate ? endDate : "",
      coupon_code: couponCode ? couponCode : "",
      couponStatus: couponStatus ? couponStatus : "",
      flied: field ? field : "",
      page: currentPage,
      limit: 10,
    };

    // if (flage == true) {
    const resp = await CouponLogsNotifcationApi(jsonData);
    if (resp.code == 200) {
      setLoading(false)
      setData(resp.data);
      setTotalPages(resp.totalPages);
    } else {
      setLoading(false)
      setCheckMargin("0px");
      setData([]);
    }
    // }
  };

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    handleSetLocalFilterItem();
  }, []);

  const handleSetLocalFilterItem = () => {
    let dataCheck = localStorage.getItem("search_filter_order") || {};
    var checkFilter = null;
    if (Object.keys(dataCheck)?.length > 0) {
      checkFilter = JSON?.parse(dataCheck);
    } else {
      checkFilter = dataCheck;
    }
    if (checkFilter?.orderfilter && checkFilter.orderfilter?.length > 0) {
      const one = new Date(checkFilter.orderfilter[0]).toISOString();
      const two = new Date(checkFilter.orderfilter[1]).toISOString();
      setOrderfilter({
        ...orderfilter,
        to: one,
        from: two,
      });
    }
    if (checkFilter?.orderStatusTwo && Object.keys(checkFilter?.orderStatusTwo)?.length > 0)
      if (checkFilter?.serviecNameOnly && checkFilter?.serviecNameOnly?.length > 0) {
      }
  };

  const handleClickDrop = (id) => {
    setShowData(id);
    setShowModal(!showModal);
  };

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "secret_code",
      text: "COUPON CODE",
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        const decryptedA = JSON.parse(decryptData(a));
        const decryptedB = JSON.parse(decryptData(b));

        const result = decryptedA.coupon_code.localeCompare(decryptedB.coupon_code);
    
        // Implement your custom comparison logic here based on decrypted values
        // For example, compare decryptedA.someField with decryptedB.someField
    
        return order === "asc" ? result : -result;
      },
      formatter: (cellContent, row) => {
        const newData = JSON.parse(decryptData(row?.secret_code));
        return (
          <>
            <p className="text-gray">
              {newData.coupon_code} /{" "}
              <img
                title={newData.coupon_code}
                src={linkIcon}
                style={{ height: "15px", cursor: "pointer" }}
                onClick={() =>
                  navigator.clipboard
                    .writeText(newData.coupon_code)
                    .then(() => {
                      showAlert("success", "Link Copied To Clipboard ");
                    })
                    .catch((error) => {
                      showAlert("error", "Copy failed!");
                    })
                }
              />
              {/* {row?.secret_code ? row?.secret_code : ""} */}
            </p>
          </>
        );
      },
    },

    {
      dataField: "createdAt",
      text: "DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {/* <p className="text-gray">{moment(row?.createdAt).local().format("MM / DD / YYYY")}</p> */}
          <p>{moment(row.createdAt).format("MM/DD/YYYY")}</p>
          <p>{moment(row.createdAt).format("hh:mm:ss A")}</p>
        </>
      ),
    },

    {
      dataField: "user_detail.email",
      text: "USER DETAILS",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {/* <p>{row?.user_detail?.email != "" ? row?.user_detail?.email : row?.user_detail?.mobile_phone ? row?.user_detail?.mobile_phone : ""}</p> */}

          <p>{row?.user_detail?.email ? row?.user_detail?.email : ""}</p>

          <p>{row?.user_detail?.mobile_phone ? row?.user_detail?.mobile_phone : ""}</p>
        </>
      ),
    },

    {
      dataField: "maximum_usage",
      text: "CREDITS",
      formatter: (cellContent, row) => {
        const newData = JSON.parse(decryptData(row?.secret_code));
        return (
          <>
            <p className="text-gray">
              {row?.used_credits_info?.length}/{newData.maximum_usage}
            </p>
          </>
        );
      },
    },

    {
      dataField: "device_type",
      text: "DEVICE",
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray" style={{ textTransform: "uppercase" }}>
            {row?.device_type || ""}
            {/* <i>{row?.coupon_detail?.maximum_usage ? row?.coupon_detail?.maximum_usage : "" }</i> */}
            {/* <i>{row.coupon_detail.mobile_phone || ""}</i> */}
          </p>
        </>
      ),
    },
    //         {
    //             dataField: "status",
    //             text: "STATUS",
    //             sort: true,
    //             formatter: (cellContent, row) => (

    // <Button
    //             type="button"
    //             className="btn-sm btn-icon btn-rounded"
    //             onClick={() => {
    //                 handleClickDrop(row)
    //             }}
    //             // onClick={toggleViewModalold}
    //           >
    //             <img src={RemoveICon} alt="remove icon" />
    //           </Button>

    //             //   <span
    //             //     className={`font-size-12`}
    //             //     style={{
    //             //       borderRadius: "10px",
    //             //       padding: "5px 10px",
    //             //       fontWeight: "bold",
    //             //       backgroundColor: row.status === "active" || row.status === "success" ? "#30e0a1" : row.status === "expired" ? "#ff7373" : "#ffdc82",
    //             //       color: "#262626",
    //             //     }}
    //             //   >
    //             //     {row.status}
    //             //   </span>
    //             ),
    //           },
  ];

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  useEffect(() => {
    if (currentPage >= 1) {
      getOrderAll();
    }
  }, [currentPage]);
  const endPage = Math.min(startPage + 4, totalPages);
  return (
    <React.Fragment>
      <CouponLogsModel isOpen={showModal} toggle={setShowModal} showData={showData} />
      <Card>
        <CardBody>
          <>
            {!loading ? (
              <>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive orders-table-main-div" id="harshu">
                          <BootstrapTable keyField="id" data={data} columns={EcommerceOrderColumns(toggle)} responsive bordered={false} striped={false} classes={"table align-middle table-nowrap table-no-check table-check"} headerWrapperClasses={"table-light"} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                

                {totalPages > 1 ?
                data && data.length > 0 ?  (
                  <div className="pagination pagination-rounded justify-content-center mt-4">
                    <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                      {"<<"}
                    </button>
                    <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                      {"<"}
                    </button>
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                      <button key={page} onClick={() => handlePageClick(page)} disabled={currentPage === page} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn btn-light  btn-xl rounded-circle m-2"}>
                        {page}
                      </button>
                    ))}
                    {totalPages > startPage + 4}
                    <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                      {">"}
                    </button>
                    <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                      {">>"}
                    </button>
                  </div>
                ) : ( <div style={{ textAlign: "center" }}>
                <p> No Data Found</p>{" "}
              </div>
            ) : ( <div style={{ textAlign: "center" }}>
                <p> No Data Found</p>{" "}
              </div>
            )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: checkmargin,
                }}
              >
                <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
              </div>
            )}
          </>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CouponLogsNotification.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(CouponLogsNotification);
