import PropTypes from "prop-types";
import React, { useEffect, useState, } from 'react';

import { Switch,  BrowserRouter as Router, } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, notAuthenticatedRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import ReactModal from 'react-modal';
// Import scss
import "./assets/scss/theme.scss";

import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

import UserContxtCheck, { theme } from "./context/theme.context";

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();

  const [modalisopen, setmodalisopen] = useState(false);
  const [isdisabled, setisdisabled] = useState(false);

 

  // const handleNetworkChange = () => {
  //   if (!navigator.onLine) {
  //     setmodalisopen(true)
  //   } else {
  //     setmodalisopen(false)
  //   }
  // };

  // const handleNetworkChange = () => {
  //   if (!navigator.onLine) {
  //     setmodalisopen(true);
  //     setHasReloaded(false); // Reset the reload flag when going offline
  //   } else {
  //     setmodalisopen(false);
  //     if (!hasReloaded) {
  //       setHasReloaded(true); // Set the reload flag to true to prevent multiple reloads
  //       window.location.reload(); // Refresh the page when the network status changes to online
  //     }
  //   }
  // };


  // useEffect(() => {

  //   handleNetworkChange()
  //   window.addEventListener('offline', handleNetworkChange);
  //   window.addEventListener('online', handleNetworkChange);

  //   return () => {
  //     window.removeEventListener('offline', handleNetworkChange);
  //     window.removeEventListener('online', handleNetworkChange);

  //   };
  // }, []);

  return (
    <React.Fragment>

{/* <ReactModal
        isOpen={modalisopen}
        // onRequestClose={() => setmodalisopen(false)}
        style={{
          overlay: {
            background: " rgba(0, 0, 0, .50)",
            zIndex: "999",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "570px",
            height: "100%",
            width: "100%",
            border: "none",
            padding: "0px",
            animation: "pop-up 0.5s forwards",
            overflow: "hidden",
          },
        }}
      >

        <div className="page justify-content-start">
          <div className="container-fluid">
            <div className="text-center">
              <img
                src={logo}
                alt=""
                className="error-logo-main-new mt-3 mb-5 d-block m-auto"
              />
              <img
                src={nointernet}
                alt=""
                className="nointer-illus-img-main my-3 d-block m-auto"
              />
              <h3 className="text-center mt-5">You're offline</h3>
              <p className="text-center mb-3 w-50 m-auto">
                please connect to the internet and try again
              </p>

              <button
                onClick={() => {
                  handleNetworkChange();
                  setisdisabled(true)
                  setTimeout(() => {
                    setisdisabled(false)
                  }, 2000);
                }}
                className="btn btn-primary mt-4"
                disabled={isdisabled}
              >
                Retry
              </button>
            </div>
          </div>
        </div>


      </ReactModal> */}

      <UserContxtCheck.Provider value={theme}>
        <Router>
          <Switch>

            {notAuthenticatedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </UserContxtCheck.Provider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
