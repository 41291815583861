import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Card, CardBody, Col, Row, Badge, Spinner } from "reactstrap";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import ViewICon from "./../../assets/icons/view-icon.svg";
//redux
import { getAllService } from "../../service/pageService";
import { showAlert } from "components/Common/AlertMessage";
import ShowPageModal from "pages/ContentManager/showPage";
import EditModal from "pages/ContentManager/editPage";
import ShowService from "pages/Service/showService";
// import { RichTextEditor } from '@mantine/rte';

const ServiceTable = (props) => {
  const [pageData, setPageData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showData, setShowData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [editModal, setEditmodal] = useState(false);
  const [editData, setEditData] = useState({});
  const selectRow = {
    mode: "none",
  };
  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: pageData.length, // replace later with size(orders),
    custom: true,
  };

  const toggleShowModal = (data) => {
    setShowData(data);
    setShowModal(!showModal);
  };

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "name",
      text: "PAGE",
      sort: true,
      formatter: (cellContent, row) => <>{row.name}</>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/services/edit/${row?._id}`}>
            <img title={"Edit"} src={EditIcon} alt="edit icon" />
          </Link>
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-icon btn-rounded"
            onClick={() => {
              toggleShowModal(row);
            }}
          >
            <img title={"View"}  src={ViewICon} alt="view icon" />
          </Button>
        </div>
      ),
    },
  ];
  const toggle = () => {
    setModal(!modal);
  };
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];
  const handlefetchPageData = async () => {
    setLoading(true);
    let resp = await getAllService();
    if ((resp.code = 200)) {
      setLoading(false);
      setPageData(resp.data);
    } else {
      showAlert("error", resp.error.massage || "There Is a error try again");
    }
  };
  useEffect(() => {
    handlefetchPageData();
  }, []);

  return (
    <React.Fragment>
      <ShowService
        isOpen={showModal}
        toggle={setShowModal}
        showData={showData}
      />
      {/* <EditModal isOpen={editModal} toggle={setEditmodal} showData={editData} handlefetchPageData={handlefetchPageData} /> */}
      <Card>
        <CardBody>
          {!loading ? (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={EcommerceOrderColumns(toggle)}
              data={pageData || []}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={pageData || []}
                  columns={EcommerceOrderColumns(toggle)}
                  bootstrap4
                  search
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12" className="custom-table">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="id"
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-twiceBar table-no-check table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="pagination pagination-rounded justify-content-center">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{ width: "2rem", height: "2rem" }}
                children={false}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(ServiceTable);
