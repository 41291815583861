import React, { useRef, useState, useEffect } from "react";
import { Card,CardBody,Col,Container,Form,Input,Label,Row } from "reactstrap";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { uplodeImage, updatePage } from "../../service/pageService";
import { showAlert } from "components/Common/AlertMessage";

const EcommerceOrdersModal = (props) => {
  const { isOpen, toggle, showData, handlefetchPageData } = props;
  const pic =
    "https://media.istockphoto.com/vectors/no-photo-available-vector-icon-default-image-symbol-picture-coming-vector-id1354776450?s=612x612";
  const inputRef = useRef(null);
  const [rufImge, setRufImage] = useState("");
  const [image, setImage] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [data, setData] = useState({
    title: "",
    slug: "",
    image: "",
    content: "",
  });
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
    };
    reader.readAsDataURL(fileObj);
    if (!fileObj) {
      return;
    }
    setImageObject(fileObj);
  };

  const HandleInputField = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmitChange = async () => {
    let img = null;
    if (imageObject) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", imageObject);
      let imageurl = await uplodeImage(bodyFormData);
      img = imageurl.data.path;
    }
    if (img) {
      data.image = img;
    } else {
      data.image = rufImge;
    }
    const resp = await updatePage(showData._id, data);
    if (resp.code == 200) {
      showAlert("success", "Content Updated  Successfully");
      toggle(false);
      handlefetchPageData();
    } else {
      showAlert("error", resp.error.massage || "There Is a error try again");
    }
  };

  useEffect(() => {
    setData({
      ...data,
      title: showData?.title,
      slug: showData?.slug,
      image: "",
      content: showData?.content,
    });
    setRufImage(showData.image);
    setImage(`${process.env.REACT_APP_API_URL}/${showData.image}`);
  }, [showData]);

  const { title, slug, content } = data;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => toggle(false)}
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggle(false)}>Content Edit</ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Card>
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Row>
                        <Col sm="4" className="mb-3">
                          <Label className="form-label">Headline</Label>
                          <Input
                            value={title}
                            name="title"
                            className="form-control"
                            placeholder="Enter title"
                            type="text"
                            onChange={HandleInputField}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="8" className="mb-3">
                          <div className="textarea-content border-bottom-gray2 pb-4">
                            <Label className="form-label">Content</Label>
                            <Input
                              value={content}
                              name="content"
                              className="form-control text-area-custom"
                              placeholder="Enter content"
                              type="textarea"
                              onChange={HandleInputField}
                            />
                          </div>
                          <div className="form-action pt-3"></div>
                        </Col>
                        <Col sm="4" className="mb-3">
                          <div className="profile-picture24 ">
                            <img
                              src={image == "" ? pic : image}
                              alt="picture new"
                              crossOrigin="anonymous"
                              className="img-fluid w-100"
                            />
                          </div>
                          <div className="field-group mt-4">
                            <Label className="form-label">About us image</Label>
                            <Input
                              value={slug}
                              name="slug"
                              className="form-control"
                              placeholder="uploaded image name"
                              type="text"
                              onChange={HandleInputField}
                            />
                          </div>
                          <div className="upload-profile d-flex align-items-start mt-3">
                            <input
                              style={{ display: "none" }}
                              ref={inputRef}
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleFileChange}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary me-3"
                              onClick={handleClick}
                            >
                              Upload
                            </button>
                            <p className="text-gray">
                              Accepted formats: JPG, PNG Your image must be up
                              to 512x512px and cannot be over 2MB.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="btn btn-primary me-3"
            onClick={handleSubmitChange}
          >
            Save Changes
          </Button>
          <Button type="button" color="secondary" onClick={() => toggle(false)}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EcommerceOrdersModal;
