import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { getOrderResultById } from "../../service/orderResultService";
import UnknownIcon from "./../../assets/icons/close-filled.svg";
import PartialIcon from "./../../assets/icons/partial-icon.svg";
import { useParams } from "react-router-dom";
import moment from "moment";
import './index.css';

const SearchResultDetails = () => {
  const [open, setOpen] = useState("1");
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [backUpdata, setBackUpdata] = useState([]);
  const [loading, setLoading] = useState(false);



  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(async () => {
    setLoading(true);
    const resp = await getOrderResultById(id);
    if (resp.code == 200) {
      setBackUpdata(resp.data[0]);
      setOrderData(resp.data[0]);
    } else {
      setLoading(false);
      //result.criminal
    }
  }, [id]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Search Result Details | BrightCheck</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Order"
          /> */}
          <div className="d-block py-2 pb-3">
            <Link to="/search-results" className="text-gray text-hover-gray">
              <i className="bx bx-arrow-back"></i> Back to Search Results{" "}
            </Link>
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={9}>
              <h5 className="text-gray">Results Returned</h5>
              <div className="result-accordion">
                <Accordion open={open} toggle={toggle}>
                  {/* Anti Cash Fish  */}


                  {orderData?.order?.map((w, i) => {
                    if (
                      w?.service_name?.toLowerCase().includes("anti-catfish")
                    ) {
                      return (
                        <AccordionItem>
                          <AccordionHeader targetId={`${i + 1}`}>
                            Anti Catfish Check
                          </AccordionHeader>
                          {/* {w?.result.anti_catfish && (  */}

                          <AccordionBody accordionId={`${i + 1}`}>
                            <Row className="border-bottom-gray2 pb-2 mb-2">
                              <Col sm={6}>
                                <p className="text-gray mb-2">First Name</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  {/* <img src={UnknownIcon} alt="close dark icon" className="w-5" /> */}
                                  <b>{orderData?.first_name}</b>{" "}
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.first_name ==
                                      "match"
                                      ? "success"
                                      : w?.result?.anti_catfish?.first_name ==
                                        "partial"
                                        ? "partial"
                                        : "unknown"
                                      } badge bg-secondary ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.first_name ==
                                      "match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.first_name ==
                                      "partial" ? (
                                      <img
                                        src={PartialIcon}
                                        alt="close dark icon"
                                        className="w-1"
                                        style={{
                                          paddingRight: "7px",
                                          paddingBottom: "2px",
                                          width: "22px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UnknownIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          width: "22px",
                                        }}
                                      />
                                    )}{" "}

                                    {/* {Object.keys(w?.result.anti_catfish?.first_name).map(([key, value]) => {

                                      {
                                        value == "match"
                                        ? "Verified"

                                        : value == "partial" ? "partial" : "Not Verified"
                                      }
                                    })} */}

                                   
                                    {
                                     
                                    w?.result?.anti_catfish?.first_name == "match"
                                      ? "Verified"
                                      : w?.result?.anti_catfish?.first_name ==
                                        "partial"
                                        ? "Partial"
                                        : "Not Verified"
                                        
                                        
                                        }
                                  </span>
                                </h5>
                              </Col>
                              <Col sm={6}>
                                <p className="text-gray mb-2">Last Name</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  <b>{orderData?.last_name}</b>
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.last_name == "match"
                                      ? "success"
                                      : w?.result?.anti_catfish?.last_name ==
                                        "partial"
                                        ? "partial"
                                        : "unknown"
                                      } badge bg-secondary ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.last_name ==
                                      "match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.last_name ==
                                      "partial" ? (
                                      <img
                                        src={PartialIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          paddingBottom: "2px",
                                          width: "22px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UnknownIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          width: "22px",
                                        }}
                                      />
                                    )}
                                    {w?.result?.anti_catfish?.last_name == "match"
                                      ? "Verified"
                                      : w?.result?.anti_catfish?.last_name ==
                                        "partial"
                                        ? "Partial"
                                        : "Not Verified"}
                                  </span>
                                </h5>
                              </Col>
                            </Row>
                            <Row className="border-bottom-gray2 pb-2 mb-2">
                              <Col sm={6}>
                                <p className="text-gray mb-2">State</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  <b>{orderData?.state}</b>
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.state == "match"
                                      ? "success"
                                      : w?.result?.anti_catfish?.state ==
                                        "partial"
                                        ? "partial"
                                        : "unknown"
                                      } badge bg-secondary ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.state == "match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.state ==
                                      "partial" ? (
                                      <img
                                        src={PartialIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          paddingBottom: "2px",
                                          width: "22px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UnknownIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          width: "22px",
                                        }}
                                      />
                                    )}
                                    {w?.result?.anti_catfish?.state == "match"
                                      ? "Verified"
                                      : w?.result?.anti_catfish?.state == "partial"
                                        ? "Partial"
                                        : "Not Verified"}
                                  </span>
                                </h5>
                              </Col>
                              <Col sm={6}>
                                <p className="text-gray mb-2">City</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  <b>{orderData?.city}</b>
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.city == "match"
                                      ? "success"
                                      : w?.result?.anti_catfish?.city ==
                                        "partial"
                                        ? "partial"
                                        : "unknown"
                                      } badge bg-secondary ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.city == "match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.city ==
                                      "partial" ? (
                                      <img
                                        src={PartialIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          paddingBottom: "2px",
                                          width: "22px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UnknownIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          width: "22px",
                                        }}
                                      />
                                    )}
                                    {w?.result?.anti_catfish?.city == "match"
                                      ? "Verified"
                                      : w?.result?.anti_catfish?.city == "partial"
                                        ? "Partial"
                                        : "Not Verified"}
                                  </span>
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <p className="text-gray mb-2">Age</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  <b>{orderData?.age}</b>
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.yob == "match"
                                      ? "success"
                                      : w?.result?.anti_catfish?.yob == "partial"
                                        ? "partial"
                                        : "unknown"
                                      } badge bg-secondary ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.yob == "match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.yob ==
                                      "partial" ? (
                                      <img
                                        src={PartialIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          paddingBottom: "2px",
                                          width: "22px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UnknownIcon}
                                        alt="close dark icon"
                                        className="w-5"
                                        style={{
                                          paddingRight: "7px",
                                          width: "22px",
                                        }}
                                      />
                                    )}
                                    {w?.result?.anti_catfish?.yob == "match"
                                      ? "Verified"
                                      : w?.result?.anti_catfish?.yob == "partial"
                                        ? "Partial"
                                        : "Not Verified"}
                                  </span>
                                </h5>
                              </Col>
                              <Col sm={6}>
                                <p className="text-gray mb-2">Married Status</p>
                                <h5 className="text-gray title-width d-flex align-items-center">
                                  {/* {w?.result?.anti_catfish?.marital_status ==
                                  "match" ? (
                                    <b>Married</b>
                                  ) : w?.result?.anti_catfish?.marital_status ==
                                    "partial" ? (
                                    <b>Single</b>
                                  ) : (
                                    <b>Single</b>
                                  )} */}
                                  {/* <b>Married</b> */}
                                  <span
                                    className={`bg-${w?.result?.anti_catfish?.marital_status ===
                                      "match"
                                      ? "danger"
                                      : w?.result?.anti_catfish
                                        ?.marital_status === "no_match"
                                        ? "success"
                                        : "warning"
                                      } badge ms-3`}
                                  >
                                    {w?.result?.anti_catfish?.marital_status ===
                                      "match" ? (
                                      <i className="bx bxs-x-circle svg-black me-1"></i>
                                    ) : w?.result?.anti_catfish?.marital_status ===
                                      "no_match" ? (
                                      <i className="bx bxs-check-circle svg-black me-1"></i>
                                    ) : null}
                                    {w?.result?.anti_catfish?.marital_status ===
                                      "match"
                                      ? "Married"
                                      : w?.result?.anti_catfish?.marital_status ===
                                        "no_match"
                                        ? "Single"
                                        : "Unknown"}
                                  </span>
                                </h5>
                              </Col>
                            </Row>
                          </AccordionBody>

                          {/* ) } */}
                        </AccordionItem>
                      );
                    }
                     else if (
                      w?.service_name?.toLowerCase().includes("criminal")
                    ) {
                      return (
                        <AccordionItem>
                          <AccordionHeader targetId={`${i + 1}`}>
                            Criminal Check
                          </AccordionHeader>

                          <AccordionBody accordionId={`${i + 1}`}>
                            <Row className="border-bottom-gray2 pb-2 mb-2">
                              <Col sm={6}>
                                <p className="text-gray mb-2"> Name</p>
                                <h5 className="text-gray">
                                  <b>
                                    {w?.result?.criminal[0]?.DefendantName}{" "}
                                  </b>
                                </h5>
                              </Col>
                              <Col sm={6}>
                                <p className="text-gray mb-2">Age</p>
                                <h5 className="text-gray">
                                  <b>{w?.result?.criminal[0]?.DOB} </b>
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <p className="text-gray mb-2">State</p>
                                <h5 className="text-gray">
                                  <b>{w?.result?.criminal[0]?.State} </b>
                                </h5>
                              </Col>
                              <Col sm={6}></Col>
                            </Row>
                          </AccordionBody>

                          {w?.result?.criminal?.map((r) => {
                            return (
                              <AccordionBody accordionId={`${i + 1}`}>
                                <Row className="border-bottom-gray2 pb-2 mb-2">
                                  <Col sm={6}>
                                    <div className="d-flex align-items-center">
                                      <p className="text-gray mb-2">
                                        Criminal Record
                                      </p>
                                      <h5 className="text-gray">
                                        <span
                                          className={`bg-${r?.OffenseType === "" ? "success" : r?.OffenseType === "Sex Offender" ? "unknown" : "warning"
                                            } badge ms-3`}
                                        >
                                          {r?.OffenseType === "" ? null :
                                            <i className="bx bxs-error svg-black me-1"></i>
                                          }
                                          {r?.OffenseType === "" ? 'Unknown' : r?.OffenseType}
                                        </span>
                                      </h5>
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      File Number
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.FileNumber} </b>
                                    </h5>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">Court Room</p>
                                    <h5 className="text-gray">
                                      <b>{r?.CourtRoom} </b>
                                    </h5>
                                  </Col>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Defendant Name
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.DefendantName} </b>
                                    </h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Offense Description
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.OffenseDescription} </b>
                                    </h5>
                                  </Col>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Offense Number
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.OffenseNumber} </b>
                                    </h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Offense Type
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.OffenseType} </b>
                                    </h5>
                                  </Col>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Source Name
                                    </p>
                                    <h5 className="text-gray">
                                      <b>{r?.SourceName} </b>
                                    </h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <p className="text-gray mb-2">
                                      Record Date
                                    </p>
                                    <h5 className="text-gray">
                                      <b>
                                        {r?.Record_date !== ""
                                          ? r?.Record_date
                                          : ""}{" "}
                                      </b>
                                    </h5>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            );
                          })}
                        </AccordionItem>
                      );
                    } else if (
                      w?.service_name?.toLowerCase().includes("social")
                    ) {
                      return (
                        <AccordionItem>
                          <AccordionHeader targetId={`${i + 1}`}>
                            Social Check
                          </AccordionHeader>
                          <AccordionBody accordionId={`${i + 1}`}>
                            {w?.result.social?.email && (
                              <>
                                <h5 className="text-gray title-width mb-3 d-flex align-items-center">
                                  <b>Email :- </b>
                                  <span className="ms-3">
                                    <strong>
                                      {w?.result.social?.email}
                                    </strong>
                                  </span>
                                </h5>
                                <ul className="social-check-email-list">
                                  {Object.keys(w?.result.social?.email_data).length > 0 && Object.entries(w?.result.social?.email_data).map(([key, value]) => {
                                    return (

                                      <li key={key}>
                                        <img width={25} height={25} src={require(`../../assets/icons/social/${key}.png`).default} alt={`${key}-logo`} />
                                        <p>{key}</p>
                                        <span
                                          className={`bg-${value
                                            ? "success"
                                            : "unknown"
                                            } badge bg-secondary ms-3`}
                                        >
                                          {value ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}{" "}
                                          {value
                                            ? "Verified"
                                            : "Unknown"}
                                        </span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </>
                            )}
                            {w?.result.social?.phone && (
                              <>
                                <h5 className="text-gray title-width mt-5 mb-3  d-flex align-items-center">
                                  <b>Phone No. :- </b>
                                  <span className="ms-3">
                                    <strong>
                                      {w?.result.social?.phone}
                                    </strong>
                                  </span>
                                </h5>
                                <ul className="social-check-phone-list">
                                  {Object.keys(w?.result.social?.phone_data).length > 0 && Object.entries(w?.result.social?.phone_data).map(([key, value]) => {

                                    var src = ""

                                    try {
                                      src = require(`../../assets/icons/social/${key}.png`).default
                                    } catch (err) {

                                    }



                                    return (
                                      <li key={key}>
                                        <img width={25} height={25} src={src} alt={`${src}-logo`} />
                                        <p>{key}</p>
                                        <span
                                          className={`bg-${value
                                            ? "success"
                                            : "unknown"
                                            } badge bg-secondary ms-3`}
                                        >
                                          {value ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}{" "}
                                          {value
                                            ? "Verified"
                                            : "Unknown"}
                                        </span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </>
                            )}
                          </AccordionBody>
                        </AccordionItem>
                      );
                    }
                  })}
                </Accordion>
              </div>
            </Col>
            <Col lg={3}>
              <Card className="theme-boxed bg-primary">
                <CardBody>
                  <Card className="mb-0">
                    <CardBody>
                      <h5 className="text-gray">TIMES SEARCHED</h5>
                      <h2 className="mb-4">{orderData.timesearch || 1}</h2>
                      <h5 className="text-gray">LAST CHECKED ON</h5>
                      <p className="mb-0 text-gray">
                        {" "}
                        {moment(new Date(orderData.createdAt)).format(
                          "DD / MM / YYYY"
                        )}
                      </p>
                      <p>
                        {" "}
                        {moment(new Date(orderData.createdAt)).format(
                          " h:mm:ss a"
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchResultDetails;
