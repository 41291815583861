import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import "react-phone-number-input/style.css";
import UserProfilePic from "./../../assets/images/profileicon.png";
import UserRessetPasswordModal from "../../pages/Users/resetPasswordModal";
import { updateAdminUser } from "../../helpers/apis/index";
import { showAlert } from "../Common/AlertMessage";
import { getUserById } from "../../service/userService";
import {
  uplodeImage,
  updatePage,
  getSingleServiceData,
  updateService,
} from "../../service/pageService";
import moment from "moment";
const UserProfile = (props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [authUser, setauthUser] = useState();
  const [fieldData, setFieldData] = useState({});
  const toggle = () => setIsOpen(!isOpen);
  const [image, setImage] = useState("");
  const [imageObject, setImageObject] = useState(null);

  const [imgurl, setImgUrl] = useState("");
  const [errorShow, setErrorShow] = useState({
    code: false,
    noum: false,
    email: false,
    noumt: "",
    emailt: "",
  });
  const styles = {
    color: "red",
    fontSize: "12px",
  };
  const HandleInputField = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };


  const handleFileChange = (event) => {
    if (!event.target.files[0].type.includes("image")) {
      return showAlert("error", "Please select only images.");
    }
    if (event.target.files[0].size > 2 * 1024 * 1024) {
      return showAlert("error", "Image size must be less than 2 mb.");
    }
    const fileObj = event.target.files && event.target.files[0];

    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
    };
    setImgUrl(fileObj.name)
    reader.readAsDataURL(fileObj);
    if (!fileObj) {
      return;
    }
    setImageObject(fileObj);
  };
  const validateCheck = () => {
    let checkstatus = false;
    let datasend = errorShow;
    if (fieldData.email == "" || fieldData.email == null) {
      if (fieldData.mobile_phone == "" || fieldData.mobile_phone == null) {
        checkstatus = true;
        datasend.noum = true;
        datasend.noumt = "Please Enter Phone No. Or Email";
      }
    }
    if (fieldData.mobile_phone == "" || fieldData.mobile_phone == null) {
      if (fieldData.email == "" || fieldData.email == null) {
        checkstatus = true;
        datasend.noum = true;
        datasend.noumt = "Please Enter Phone No. Or Email";
      }
    }
    setErrorShow(datasend);
    return checkstatus;
  };

  const handleSubmit = async (e) => {
    let data = {
      email: fieldData.email,
      mobile_phone: fieldData.mobile_phone,
      profile_image: fieldData.profile_image,
    };
    let img = null;
    if (imageObject) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", imageObject);
      let imageurl = await uplodeImage(bodyFormData);
      img = imageurl.data.path;
    }
    if (img) {
      data.profile_image = img;
    } else {
      data.profile_image = "";
    }
    const resp = await updateAdminUser(data, authUser?._id);
    if (resp.code == 200) {
      showAlert("success", "User updated successfully");
      history.push("/dashboard");
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
    }
  };
  useEffect(() => {
    handleApiCall();
  }, []);
  const handleApiCall = async () => {
    const auth_user = JSON.parse(localStorage.getItem("authUser"));
    let resp = await getUserById(auth_user._id);
    setauthUser(resp.data.users);
    setImage(
      `${process.env.REACT_APP_API_URL}/${resp.data.users?.profile_image}`
    );
    setImgUrl(resp.data.users?.profile_image);
    setFieldData({
      ...fieldData,
      mobile_phone: resp.data.users?.mobile_phone,
      email: resp.data.users?.email,
      profile_image: `${process.env.REACT_APP_API_URL}/${resp.data.users?.profile_image}`,
      createAt: resp.data.users?.createdAt,
    });
  };
  return (
    <React.Fragment>
      <UserRessetPasswordModal
        isOpen={isOpen}
        toggle={toggle}
        id={authUser?._id}
      />
      <div className="page-content">
        <MetaTags>
          <title>
            {/* {fieldData && fieldData ? "Profile" : "User Profile"} | */}
            BrightCheck
          </title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="mb-4 h4 text-gray card-title">
                Basic Information
              </div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();

                  if (!validateCheck()) {
                    handleSubmit(e);
                  }
                }}
              >
                <Row>
                  <Row xs={"4"}>
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Phone Number*</Label>
                      <Input
                        value={fieldData.mobile_phone || ""}
                        name="mobile_without_code"
                        className="form-control"
                        placeholder="Enter phone number"
                        type="text"
                        onBlur={() => {
                          if (
                            fieldData.mobile_phone == "" ||
                            fieldData.mobile_phone == null
                          ) {
                            if (
                              fieldData.email == "" ||
                              fieldData.email == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                noum: true,
                                noumt: "Please Enter Phone No. Or Email",
                              });
                            }
                          } else if (fieldData?.mobile_phone?.length < 9) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Phone No. Must Be a 10 Digit No",
                              email: false,
                              emailt: " ",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              noum: false,
                              noumt: "",
                              email: false,
                              emailt: " ",
                            });
                          }
                        }}
                        onChange={(e) => {
                          var numbers = /^\d*\d*$/;
                          if (
                            e.target.value.match(numbers) &&
                            e.target.value.length <= 10
                          ) {
                            setFieldData({
                              ...fieldData,
                              mobile_phone: e.target.value,
                            });
                          }
                          if (
                            fieldData.mobile_phone == "" ||
                            fieldData.mobile_phone == null
                          ) {
                            if (
                              fieldData.email == "" ||
                              fieldData.email == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                noum: true,
                                noumt: "Please Enter Phone No. Or Email",
                              });
                            }
                          } else if (fieldData?.mobile_phone?.length < 9) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Phone No. Must Be a 10 Digit No",
                              email: false,
                              emailt: " ",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              noum: false,
                              noumt: "",
                              email: false,
                              emailt: " ",
                            });
                          }
                        }}
                      />

                      {errorShow.noum && (
                        <span type="invalid" style={styles}>
                          {errorShow.noumt}
                        </span>
                      )}
                    </Col>
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Email*</Label>
                      <Input
                        value={fieldData.email || ""}
                        name="email"
                        maxLength={40}
                        className="form-control"
                        placeholder="Enter email address"
                        type="text"
                        onBlur={() => {
                          var regexp = /.+@.+\.[A-Za-z]+$/;
                          if (
                            fieldData.email == "" ||
                            fieldData.email == null
                          ) {
                            if (
                              fieldData.mobile_phone == "" ||
                              fieldData.mobile_phone == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                noum: true,
                                noumt: "Please Enter Phone No. Or Email",
                                email: true,
                                emailt: "Please Enter Phone No. Or Email",
                              });
                            }
                          } else if (!regexp.test(fieldData.email)) {
                            setErrorShow({
                              ...errorShow,
                              email: true,
                              emailt: "Please Enter Valid email",
                              noum: false,
                              noumt: "",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              email: false,
                              emailt: " ",
                              noum: false,
                              noumt: "",
                            });
                          }
                        }}
                        onChange={(e) => {
                          setFieldData({
                            ...fieldData,
                            email: e.target.value,
                          });
                          setErrorShow({
                            ...errorShow,
                            email: false,
                            emailt: " ",
                          });
                        }}
                      />
                      {errorShow.email && (
                        <span type="invalid" style={styles}>
                          {errorShow.emailt}
                        </span>
                      )}
                    </Col>

                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Creation date</Label>
                      <p className="field-text1">
                        {moment(fieldData.createAt).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Profile</Label>
                      <div className="">
                        <div className="userProfile21">
                          <img
                            src={
                              image !== "" && imgurl !== "" && imgurl !== null
                                ? image
                                : UserProfilePic
                            }
                            crossOrigin="anonymous"
                            alt="pic"
                          />
                          <button className="inputFile">
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              onChange={handleFileChange}
                            />
                            <i className="bx bx-edit"></i>
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Col sm="16" className="my-3">
                    <div className="h2 border-bottom-gray pb-3 text-gray card-title">
                      SECURITY SETTINGS
                    </div>
                  </Col>
                  <Col sm="16" className="border-bottom-gray2 pb-3 mb-3">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      Reset Password
                    </button>
                  </Col>
                  <Col sm="16" className="mb-3">
                    <button
                      type="button"
                      className="btn btn-success me-3"
                      onClick={() => {
                        if (!validateCheck()) {
                          handleSubmit();
                        }
                      }}
                    >
                      Save Changes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
