import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import {Row, Col,CardBody, Card, Alert,Container, Form, Input, FormFeedback, Label, Spinner} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser, apiError } from "../../store/actions";
// import images
// import logo from "assets/images/logo/bright-swap-logo.svg";
import logo from "../../assets/images/logo/bright-check-logo.svg"
//regex

const Login = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Please Enter a Valid Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),


    onSubmit: (values) => {
      const allValues = {
        value: values.email,
        password: values.password,
        type: "email",
        match: "loginOtp"
      };
      const myhistorry = props.history;
      dispatch(loginUser(allValues, myhistorry));
    },
  });

  const { error, loading } = useSelector((state) => state.Login);

  useEffect(() => {

    setTimeout(() => {
      localStorage.setItem("status", "");

    }, 3000);
   
    dispatch(apiError(null));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title> Login | BrightCheck </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="py-5">
                    <Link to="/" className="auth-logo-light">
                      <img
                        src={logo}
                        alt="Bright Swap"
                        weight="191"
                      />
                    </Link>
                  </div>
                  <div className="py-2">
                    <h5 className="text-primary font-weight-700 text-uppercase letter-spacing-1">
                      Welcome Back !
                    </h5>
                    <p className="text-gray">
                      Sign in to continue to BrightCheck
                    </p>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-3">
                        <Label className="form-label mb-1">E-mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter your e-mail"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-5">
                        <Label className="form-label mb-1">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter your password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        {loading ? (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled
                          >
                            <Spinner
                              size="sm"
                              className="fill-white text-white"
                            >
                              Loading...
                            </Spinner>
                            <span className="text-white"> Loading</span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Log In
                          </button>
                        )}
                      </div>
                      <div className="mt-3 mb-2 text-center">
                        <Link
                          to="/forgot-password"
                          className="text-muted font-weight-500"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
