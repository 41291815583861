import React from "react";
import PropTypes from "prop-types";
import { Button,Modal,ModalBody,ModalFooter,ModalHeader } from "reactstrap";

const EcommerceOrdersModal = (props) => {
  const { isOpen, toggle, showData } = props;
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => toggle(false)}
    >
      <div className="modal-content">
        <ModalHeader style={{alignItems:"inherit"}} toggle={() => toggle(false)}>{showData.title}</ModalHeader>
        <ModalBody>
          <div>
            <p>
              <b>Content :-</b> {renderHTML(showData.content)}
            </p>
          </div>
          <div className="text-center serviceShowMedia">
            {showData && showData?.isImage && showData?.isImage && (
              <img
                src={`${process.env.REACT_APP_API_URL}/${showData.image}`}
                crossOrigin="anonymous"
                width={300}
                height={200}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => toggle(false)}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EcommerceOrdersModal;
