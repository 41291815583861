import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { createMember, Suggestion_API } from "service/notifactionService";
import { showAlert } from "components/Common/AlertMessage";
import { Spinner } from "reactstrap";
import { Input, Label } from "reactstrap";
import CreatableSelect from 'react-select/creatable';

// Added by Parth Roka 05/12/2023
const UsernameCreateNotification = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [brandName, setBrandName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [sugId, setSugId] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [UsernameValidation, setUsernameValidation] = useState(false);
  const [EmailValidation, setEmailValidation] = useState(false);
  const [brandValidation, setBrandValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  function isValidEmail(email) {
    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }

  function handleEmail(e) {
    setEmail(e);
    let value = e;
    if (value) {
      if (!isValidEmail(value)) {
        setEmailValidation(true);
        setEmailError("Please Enter Valid Email");
      } else {
        setEmailValidation(false);
        setEmailError("");
      }
    } else {
      setEmailError("Please Enter Email");
      setEmailValidation(true);
    }
  }


  const handleInputchange = (e) => {
    if (e != "") {
      setUsername({ label: e.label, value: e.value });
    }
    if (e.length >= 3) {
      suggestionsCall(e);
    } else {
      // Clear suggestions when input length is less than 3
      setSuggestions([]);
    }
  };
  const handleSelect = (e) => {
    setUsernameError("")
    if (e != "") {
      setUsernameValidation(false)
      setUsername({ label: e.label, value: e.value });
      if(e.__isNew__ == true){
        setSugId(null)
      }else{
        setSugId(e.value)
      }

    }
  };

  // function handleUsername(e) {

  //   const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
  //   // const result =/^[A-Za-z]( ?[A-Za-z])*$/;
  //   if (e.target.value) {
  //     if (e.target.value === "" || result.test(e.target.value)) {
  //       setUsername(e.target.value);
  //       setSugId(null);
  //       setUsernameValidation(false);
  //       setUsernameError("");
  //       if (e.target.value.length >= 3) {
  //         suggestionsCall(e.target.value);
  //       } else {
  //         // Clear suggestions when input length is less than 3
  //         setSuggestions([]);
  //       }
  //     } else {
  //       setUsernameValidation(true);
  //       setUsernameError("Please Enter Valid Client Name");
  //     }
  //   } else {
  //     setUsernameValidation(true);
  //     setUsernameError("Please Enter Client Name");
  //     setUsername("");
  //   }
  // }

  // const handleSuggestionClick = (suggestion) => {
  //   setUsername(suggestion.membername);
  //   setSugId(suggestion._id);
  //   setSuggestions([]); // Clear suggestions after selection
  //   // setSelectedSuggestion(suggestion);
  // };

  function handleBrandName(e) {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
    if (e.target.value) {
      if (e.target.value === "" || result.test(e.target.value)) {
        setBrandName(e.target.value);
        setBrandValidation(false);
        setBrandError("");
      } else {
        setBrandValidation(true);
        setBrandError("Please Enter Valid Brand Name");
      }
    } else {
      setBrandValidation(true);
      setBrandError("Please Enter Brand Name");
      setBrandName("");
    }
  }

  // main function
  const handleSubmit = (e) => {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
    if (email) {
      if (!isValidEmail(email)) {
        setEmailError("Please Enter Valid Email");
      } else {
        setEmailError("");
      }
    } else {
      setEmailError("Please Enter Email");
    }

    if (username != "" ){
     if (username.value != undefined) {
      setUsernameError("")
    }else{
      setUsernameError("Please Enter Client Name");
    } 
    } else {
      setUsernameError("Please Enter Client Name");
    }

    if (brandName) {
      if (result.test(brandName)) {
        setBrandError("");
      } else {
        setBrandError("Please Enter Valid Brand Name");
      }
    } else {
      setBrandError("Please Enter Brand Name");
    }

    if (EmailValidation == false && UsernameValidation == false && brandValidation == false && brandName && email && username) {
      getNotifcation();
    }
  };

  const suggestionsCall = async (value) => {
    const res = await Suggestion_API(value);
    if (res.code == 200) {
      setSuggestions(res.data);
    } else {
      if (res.code == 400) {
        setSuggestions([]);
      } else {
      }
    }
  };

  // for create coupon API
  const getNotifcation = async () => {
    setLoading(true);
    let dataBody = {
      email: email,
      client_name: username.label,
      client_id: sugId ?? null,
      brand_name: brandName,
    };

    const res = await createMember(dataBody);
    if (res.code == 200) {
      showAlert("success", "Username Create Successful!");
      setLoading(false);
      setEmail("");
setBrandName("")
      setUsername("");
      props.customActiveTab("3", false, false, false, true);
    } else {
      setLoading(false);
      if (res.code == 500) {
        showAlert("error", res?.data?.message);
      } else {
        showAlert("error", "There Is a Error Try Again");
      }
    }
    setLoading(false)
  };

  const noOptionsMessage = ({ inputValue }) => (inputValue.length >= 3 ? "No options found" : null);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // Adjust the border radius
     // Adjust the border color
    // Remove box shadow
    }),
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <Label className="form-label mb-1">Client Name* :</Label>
              {/* <Input
            type="text"
            maxLength={30}
            value={username}
            onChange={(e) => handleUsername(e)}
            placeholder='Client Name'
          />
          {suggestions.length > 0 && (
        <ul>
          {suggestions.map((suggestion) => (
            <li className='cursor-pointer' onClick={() => handleSuggestionClick(suggestion)} key={suggestion._id}>{suggestion.membername}</li>
          ))}
        </ul>
      )} */}



              <CreatableSelect
                id="selectCenter"
              options={suggestions && suggestions.length > 0 ? suggestions.map((e) => ({ label: e.membername, value: e._id })) : []}
              noOptionsMessage={noOptionsMessage}
                value={username}
                placeholder={"Client Name"}
                styles={customStyles}
                onInputChange={(e) => handleInputchange(e)}
                onChange={(e) => handleSelect(e)}
              />
              {/* <Select
                id="selectCenter"
                options={suggestions && suggestions.length > 0 ? suggestions.map((e) => ({ label: e.membername, value: e.membername })) : []}
                // isClearable={false}
                noOptionsMessage={noOptionsMessage}
                value={username}
                // value={username.value}
                // inputValue={username.value}
                placeholder={"Client Name"}
                styles={customStyles}
                // defaultValue={username}
                onInputChange={(e) => handleInputchange(e)}
                onChange={(e) => handleSelect(e)}
              /> */}
              <p className="textdanger"> {usernameError}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Label className="form-label mb-1">Brand Name* :</Label>
              <Input type="text" maxLength={30} value={brandName} onChange={(e) => handleBrandName(e)} placeholder="Name" />
              <p className="textdanger"> {brandError}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Label className="form-label mb-1">Email* :</Label>
              <Input type="text" maxLength={40} value={email} onChange={(e) => handleEmail(e.target.value.trim())} placeholder="Email" />
              <p className="textdanger"> {emailError}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <button className="btn btn-primary btn-block" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: checkmargin,
          }}
        >
          <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
        </div>
      )}
    </>
  );
};

UsernameCreateNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(UsernameCreateNotification);
