import OrderTable from "components/Order/OrderTable";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";

const Orders = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Order | BrightCheck</title>
        </MetaTags>
        <Container fluid>
        </Container>
        <OrderTable />
      </div>
    </React.Fragment>
  );
};

export default Orders;
